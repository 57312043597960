<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <Row :gutter="16">
        <Col span="6">
          <FormItem label="商品编号:" prop="Code">
            <Input
              placeholder="请输入商品编号"
              v-model="params.Code"
              clearable
            />
          </FormItem>
        </Col>
        <Col span="6">
          <FormItem label="商品名称:" prop="Name">
            <Input
              placeholder="请输入商品名称"
              v-model="params.Name"
              clearable
            />
          </FormItem>
        </Col>
        <i-col span="6">
          <FormItem label="日期范围:" prop="TempDate">
            <Date-picker
              style="width: 100%"
              clearable
              v-model="params.TempDate"
              ref="date"
              type="datetimerange"
              :options="options"
              placement="bottom-end"
              placeholder="日期范围"
              format="yyyy-MM-dd HH:mm:ss"
              @on-change="dateUpdate"
            ></Date-picker>
          </FormItem>
        </i-col>
        <!-- <Col span="6" class="mt5" style="text-align: right">
          <Button
            class="ml2"
            size="small"
            type="primary"
            icon="ios-search"
            @click="handleSearch"
            >搜索</Button
          >
          <Button class="ml2" size="small" @click="handleReset">重置</Button>
          <Button
            type="success"
            class="ml2"
            size="small"
            icon="md-cloud-download"
            @click="exportData"
            >导出</Button
          >
        </Col> -->
      </Row>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            class="ml2"
            icon="md-cloud-download"
            @click="exportData"
          >
            导出
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
      stripe
    >
      <template slot="MemberView" slot-scope="{ row }">
        <span
          style="color: green; cursor: pointer"
          @click="handleOpenUser(row.MemberCode)"
        >
          {{ row.MemberCode }}
        </span>
      </template>

      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="primary"
          @click="openDrawer(row)"
          v-if="!row.isEdit && $canAction(['Stat_Search'])"
        >
          导出明细
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          :page-size-opts="[200, 500, 1000]"
          :page-size="40"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <!--    用户信息编辑抽屉-->
    <UserProfileDrawer ref="userDrawer" />
  </Layout>
</template>

<script>
import api from "@/api";
import Layout from "@/components/layout/index.vue";
import moment from "moment";
export default {
  name: "StatManage",
  data() {
    return {
      BonusView: false,
      tableLoading: false,
      tableHeight: 0,
      RowID: 0,
      Amount: 0,
      DrawBonus: 0,
      TotalAmount: 0,
      BonusLoading: false,
      params: {
        RealName: "",
        MobilePhone: "",
        Code: "",
        Name: "",
        DateBegin: "",
        DateEnd: "",
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Desc",
      },
      columns: [
        //{ title: "主播名称", key: "RealName", minWidth: 120, align: "center" },
        // {
        //   title: "手机号码",
        //   key: "MobilePhone",
        //   minWidth: 95,
        //   align: "center",
        // },
        { title: "商品编号", key: "Code", minWidth: 95, align: "center" },
        { title: "商品名称", key: "Name", minWidth: 200, align: "left" },
        { title: "实际销量", key: "TotalCount", minWidth: 95, align: "center" },
        { title: "利润", key: "AnchorBonus", minWidth: 95, align: "center" },
        { title: "销售价", key: "Price", minWidth: 95, align: "center" },
        { title: "内部价", key: "InsidePrice", minWidth: 95, align: "center" },
        {
          title: "总利润",
          key: "TotalBonus",
          minWidth: 95,
          align: "center",
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      showUserProfileDrawer: false,
      rowCopy: null,
    };
  },
  created() {
    // if (this.$route.meta.tableActionWidth) {
    //   this.columns.push({
    //     title: "操作",
    //     key: "action",
    //     slot: "Action",
    //     align: "right",
    //     fixed: "right",
    //     minWidth: this.$route.meta.tableActionWidth,
    //     maxWidth: this.$route.meta.tableActionWidth,
    //   });
    // }
    this.loadMemberStatList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadMemberStatList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadMemberStatList();
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadMemberStatList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    exportData() {
      try {
        this.$refs.dataTable.exportCsv({
          filename: "业绩统计 " + moment().format("YYYY-HH-MM hh点mm分ss秒"),
          columns: this.columns.filter((col, index) => index < 8),
          data: this.resObj.DataList,
        });
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        // TODO: 记录错误日志
      }
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadMemberStatList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadMemberStatList();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.DateBegin = value[0];
      this.params.DateEnd = value[1];
    },
    async loadMemberStatList() {
      this.tableLoading = true;
      try {
        const res = await api.GetMemberStatList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadMemberStatList();
    },
    handleReset() {
      this.params.DateBegin = "";
      this.params.DateEnd = "";
      this.$refs.SearchForm.resetFields();
      this.loadMemberStatList();
    },
    // openDrawer(row) {
    //   this.$refs.bonusDetailDrawer.id = row.MemberCode;
    //   // this.$refs.bonusDetailDrawer.obj = row;
    //   this.$refs.bonusDetailDrawer.isShow = true;
    // },
    async handleOpenUser(MemberID) {
      try {
        const res = await api.GetMemberByCode({
          code: MemberID,
        });
        if (res.Status === 100) {
          this.$refs.userDrawer.obj = res.Data;
          this.$refs.userDrawer.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
  },
  components: {
    Layout,
  },
};
</script>
